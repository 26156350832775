import {store} from "./index";
import {delayConfiguration, startConfiguration, subscribe, unsubscribe} from "pusher-redux";
import {ROOM_SUBSCRIBED} from "./rooms/reducer";
import {CLEAR_AUTH} from "./auth/reducer";
import Axios from "axios";


let authorizer = (token) => (channel, options) => {


    return {
        authorize: (socketId, callback) => {
            try {

            } catch (e) {
            }
        }
    };
};

delayConfiguration(store, "0ed7e6a5e78ae6e4a39d");


export const initPusher = (token) => {
    const authUrl = process.env.REACT_APP_BASE_API + "/user";

    Axios.get(authUrl, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
        },
    })
        .then(res => {
            if (res.status !== 200) {
                throw new Error(`Received ${res.status} from ${authUrl}`);
            }
            startConfiguration({
                cluster: "eu",
                auth: {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Accept": "application/json"
                    }
                },
                authEndpoint: process.env.REACT_APP_BASE_CHANNEL + "/broadcasting/auth",
            });
        })
        .catch(err => {
            store.dispatch(CLEAR_AUTH());

        });


}

export const getGameRoom = (gameRoom, event: string = 'pusher:subscription_succeeded', type: string = ROOM_SUBSCRIBED.type) => {
    return subscribe(`presence-game-room.${gameRoom}`, event, type);
}

export const forgetGameRoom = gameRoom => {
    if (gameRoom) {
        try {
            return unsubscribe(`presence-game-room.${gameRoom}`);
        } catch (e) {
        }
    }
}
