import {createAction, createAsyncThunk, createReducer} from "@reduxjs/toolkit";
import Axios from "axios";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
export const baseApi = `${process.env.REACT_APP_BASE_API}/`;

export const userAuthenticate = createAsyncThunk('auth/userLogin', (username, thunkAPI) => {
    return Axios.post(baseApi + "tempAuth", {
        username: username
    }, {
        headers: {
            "Accept": "application/json"
        }
    }).then(value => {
        console.log(value)
        return value.data;
    }).catch(reason => {
        return thunkAPI.rejectWithValue(reason.response.data);
    })
});

export const CLEAR_AUTH = createAction('auth/clear')
export const AUTHORIZED = createAction('auth/channel_ok')

export const auth = createReducer({
    logged: false,
    user: false,
    token: '',
    channel_ok: false,
    error: [],
}, builder => {
    builder.addCase(CLEAR_AUTH, state => ({
        logged: false,
        user: false,
        token: '',
        channel_ok: false,
        error: []
    }))
    builder.addCase(userAuthenticate.fulfilled, (state, action) => {
        return {
            ...action.payload,
            logged: true,
            channel_ok: false
        };
    })
    // @ts-ignore
    builder.addCase(userAuthenticate.rejected, (state, action) => {
        console.log(action)
        return {
            logged: false,
            user: false,
            token: '',
            channel_ok: false,
            // @ts-ignore
            error: action.payload?.errors ?? {generic: ["errore nell'autorizzazione"]}
        };
    })
    builder.addCase(AUTHORIZED, state => ({
        ...state,
        channel_ok: true
    }))
});

export const authReducer = persistReducer(
    {storage, key: "ptbp-gamer-auth", whitelist: ["user", "token", "logged"]},
    auth
);
