import * as React from "react";
import {Route, Switch} from "react-router-dom";
import {GameRoomPortal} from "./GamePortal";

export function Layout() {
    return <Switch>
        <Route exact path="/:gameRoom">
            <GameRoomPortal/>
        </Route>
        <Route exact path="/">
            Devi essere in una game room per giocare
        </Route>
    </Switch>;
}
