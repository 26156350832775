import {createAction, createReducer} from "@reduxjs/toolkit";
import {instantiateGame} from "../../setup/game";
import {CONNECTED} from "pusher-redux";


export const ROOM_SUBSCRIBED = createAction("ROOM_SUBSCRIBED");
export const MEMBERS_CHANGED = createAction("MEMBERS_CHANGED");
export const GAME_STARTED = createAction("GAME_STARTED");
export const GAME_PAUSED = createAction("GAME_PAUSED");

const initialState = {
    channel: '',
    data: {},
    event: '',
    started: false
};

export const roomReducer = createReducer(initialState, builder => {
    builder.addCase(CONNECTED,(state, action) => {
    })
    builder.addCase(ROOM_SUBSCRIBED, (state, action) => {
        return {
            ...action,
            started: (action?.data?.me?.info?.tags ?? []).indexOf("started") >= 0
        };
    })
    builder.addCase(MEMBERS_CHANGED, (state, action) => {
        return  {
            ...action,
            started: (action?.data?.me?.info?.tags ?? []).indexOf("started") >= 0
        };
    })
    builder.addCase(GAME_STARTED, (state, action) => {

        return {
            ...state,
            started: true
        };
    })
    builder.addCase(GAME_PAUSED, (state, action) => {
        return {
            ...state,
            started: false
        };
    })
})
