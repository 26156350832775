import './App.css';
import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {Provider} from 'react-redux';
import {persistor, store} from "./store";
import {BrowserRouter as Router} from "react-router-dom";
import {Layout} from "./Layout/Layout";
import {PersistGate} from "redux-persist/integration/react";

export default () => {
    return <Provider store={store}>
        <Router>
            <PersistGate persistor={persistor} loading={<h1>Caricamento dati...</h1>}>
                <Layout/>
            </PersistGate>
        </Router>
    </Provider>
};
