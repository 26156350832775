import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import {useEffect, useState} from "react";
import {forgetGameRoom, getGameRoom, initPusher} from "../store/pusherStore";
import {GAME_PAUSED, GAME_STARTED} from "../store/rooms/reducer";
import {gameInstantiated, instantiateGame} from "../setup/game";
import {Modal} from "react-bootstrap";
import Axios from "axios";

export const GameView = withRouter((props) => {

        // @ts-ignore
        const auth = useSelector(({auth}) => auth);
        const name = auth?.user?.name;
        const gameRoom = props?.match?.params?.gameRoom;
        const userLogged = auth?.logged;

        // @ts-ignore
        const channelData = useSelector(state => state.rooms);

        const [gameStarted, setGameStarted] = useState(false);

        const [axiosInterc, setAxiosInterc] = useState(0);

        useEffect(() => {
            if (userLogged && gameRoom && auth?.token) {


                Axios.interceptors.request.eject(axiosInterc);


                setAxiosInterc(Axios.interceptors.request.use(config => {
                    config.headers.Authorization = `Bearer ${auth.token}`;
                    config.headers.Accept = "application/json";
                    return config;
                }));
                initPusher(auth.token);

                getGameRoom(gameRoom);

            }
            return () => forgetGameRoom(gameRoom);
        }, [auth?.token, gameRoom])

        useEffect(() => {
            if (auth.channel_ok) {
                forgetGameRoom(gameRoom);
                getGameRoom(gameRoom);
                getGameRoom(gameRoom, "App\\Events\\GameRoomStarted", GAME_STARTED.type);
                getGameRoom(gameRoom, "App\\Events\\GameRoomPaused", GAME_PAUSED.type);
            }
        }, [auth?.channel_ok])

        const dispatch = useDispatch();

        useEffect(() => {

            if (channelData) {
                let gameStarted = channelData?.started ?? false;
                setGameStarted(gameStarted);

                if (gameStarted) {
                    instantiateGame(dispatch, gameRoom);
                    setTimeout(() => setShow(false), 2000);
                } else {
                    setTimeout(() => setShow(true));
                }

            }
        }, [channelData])


        const [show, setShow] = useState(true);

        return <Modal show={show} centered onHide={() => {
        }}>
            <Modal.Header><span>Benvenuto <strong>{name}</strong></span></Modal.Header>
            <Modal.Body>
                {
                    (gameStarted && <h3>Buon divertimento!</h3>) ||
                    (gameInstantiated() && <h3>Attendi, la sessione è in pausa...</h3>) ||
                    <h3>Attendi che la sessione {gameRoom} cominci...</h3>
                }
            </Modal.Body>
        </Modal>;
    }
)
