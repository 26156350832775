import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import Axios from "axios";

export const baseApi = `${process.env.REACT_APP_BASE_API}/`;

export const SEND_EVENT = createAction("SEND_EVENT");

export const sendEvent = createAsyncThunk(SEND_EVENT.type, ({game_room, event}, {dispatch, extra, getState, rejectWithValue, requestId, signal}) => {
    return Axios.post(`${baseApi}game_events/${game_room}`, {
        event: event
    }).then(value => {
    })
})
