import {useSelector} from "react-redux";
import {Container} from "react-bootstrap";
import {LoginForm} from "../partials/LoginForm";
import * as React from "react";
import {withRouter} from "react-router-dom";
import {GameView} from "./GameView";

const CleaGameRoomPortal: (props: any) => any = (props) => {
    // @ts-ignore
    const auth = useSelector(({auth}) => auth);
    const userLogged = auth?.logged;

    // @ts-ignore
    const gameStarted = useSelector(state => state?.rooms?.started);


    return <div hidden={gameStarted} className="position-fixed w-100 h-100" style={
        {
            backgroundColor: "rgba(0,0,0,.3)"
        }
    }>
        <Container className="h-100">
            {(userLogged && <GameView/>) || <LoginForm/>}
        </Container>
    </div>
}
export const GameRoomPortal = withRouter(CleaGameRoomPortal);
