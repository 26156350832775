import {sendEvent} from "../store/gameEvents/gameEventSlice";

let gameInstance = null;
const amplify = window.amplify;

export const gameInstantiated = () => {
    return gameInstance ?? false;
}

export const instantiateGame = (dispatch, game_room, force = false) => {

    if (gameInstance && !force) {
        return;
    }

    amplify.subscribe("GameInstanceCreated", function (jsonContent) {
        const dispatchEv = dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        })).then(el => console.log(el));
    });

    amplify.subscribe("GameStarted", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("GameEnded", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("GameClosed", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("GameButtonClicked", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("FullScreenButtonClicked", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("StartButtonClicked", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("NextDialogueLineButtonClicked", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });

    amplify.subscribe("PrintButtonClicked", function (jsonContent) {
        dispatch(sendEvent({
            game_room,
            event: JSON.parse(jsonContent)
        }));
    });


    gameInstance = new window.Game();
    window.game = gameInstance;
    gameInstance.initialize();


}
