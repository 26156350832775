import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import {persistStore} from "redux-persist";
import {authReducer} from "./auth/reducer";
import {roomReducer} from "./rooms/reducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    rooms: roomReducer
})

const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }),
];

export const store = configureStore({
    reducer: rootReducer,
    middleware
});

export const persistor = persistStore(store);

