import * as React from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card, Col, FormControl, Row} from "react-bootstrap";
import {userAuthenticate} from "../store/auth/reducer";
import {keys, map} from 'lodash';

export function LoginForm() {
    const [username, setUsername] = useState('');
    const dispatch = useDispatch();

    const handleClick = () => {
        // @ts-ignore
        dispatch(userAuthenticate(username));
    }

    // @ts-ignore
    const {error = {}} = useSelector(state => state?.auth);

    return <Row className="justify-content-center h-100 my-5">
        <Col xs="auto" sm={8} md={6} lg={12}>
            <Card>
                <Card.Header>Inserisci il tuo nickname</Card.Header>
                <Card.Body>
                    {
                        keys(error).length > 0 && map(error, el => <Alert variant={"danger"}>{el[0]}</Alert>)
                    }
                    <FormControl as="input" value={username} onChange={event => setUsername(event.target.value)}/>
                </Card.Body>
                <Card.Footer>
                    <Button className="float-right" variant={"success"} onClick={handleClick}>Gioca</Button>
                </Card.Footer>
            </Card>
        </Col>
    </Row>;
}
